.mol-share-contact-form__title {
    color: #000;
    font-size: toRem(25);
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-bottom: toRem(20);
}

.mol-share-contact-form__input--offset {
    margin-bottom: toRem(10);
}

.mol-share-contact-form__notes {
    margin-bottom: toRem(40);
}

.mol-share-contact-form__input--clear {
    margin-bottom: 0;
}

.mol-share-contact-form__error {
    display: block;
    // visibility: hidden;
    height: toRem(20);

    font-size: toRem(15);
    text-align: center;
    color: #d31717;

    margin-bottom: toRem(10);
} 

.mol-share-contact-form__error--visible {
    visibility: visible;
}

.mol-share-contact-form__mini-label {
    margin-top: toRem(7);
    font-size: toRem(9);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
} 

.mol-share-contact-form__input--name {
    text-transform: capitalize;
}

.mol-share-contact-form__input--email {
    text-transform: lowercase;
}

// Hide scrollbars Safari, Chrome
.modal-body::-webkit-scrollbar {
    display: none;
}

// Hide scrollbar for IE, Edge and Firefox
.modal-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.modal-backdrop {
    overflow: hidden !important;
}