.atm-user-avatar__username-wrapper {
  display: flex;
  align-items: center;

  svg {
    margin-left: toRem(10);
    height: toRem(17);
    width: toRem(17);
    flex: 0 0 toRem(17);
    margin-top: toRem(6);
    align-self: start;
  }
}

.atm-user-avatar__username {
  font-weight: 700;
  font-size: toRem(25);
  line-height: toRem(30);

  //max-width: 100%;
  //overflow: hidden;
  //text-overflow: ellipsis;
}