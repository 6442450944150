.mol-detail-header__bio {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.mol-detail-header__bio-description {
  font-weight: 400;
  font-size: toRem(14);
  line-height: toRem(17);

  white-space: pre-wrap;

  margin-top: toRem(35);
}
