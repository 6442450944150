.atm-user-company-logo {
	border-radius: toRem(32);
	width: toRem(56);
	height: toRem(56);
	border: toRem(3) solid #fff;

	-webkit-box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.14);
	-moz-box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.14);
	box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.14);

	flex: 0 0 auto;
}

.atm-user-company-logo--hidden {
	display: none;
}

.atm-user-company-logo__image {
	width: 100%;
	height: 100%;
	border-radius: 50%;

	object-fit: cover;
}
