.atm-action-btn {
  border: none;
  border-radius: toRem(10);

  width: 100%;
  height: toRem(48);

  background: #4777ee;
  color: #fff;

  font-weight: 700;
  font-size: toRem(13);
  line-height: toRem(16);

  &:hover {
    cursor: pointer;
  }

  &:disabled {
      background-color: rgba(239, 239, 239, 0.3);
      color: rgba(16, 16, 16, 0.3);
      border-color:rgba(118, 118, 118, 0.3);
  }
}
