.ogm-card-detail__wrapper {
	min-height: var(--app-height);

	background-color: #f3f3f3;
}

.ogm-card-detail__wrapper--padded {
	padding-bottom: toRem(34);
}

.ogm-card-detail__items {
	padding: toRem(22) toRem(20) toRem(75);
}

// Zafarbenie pozadia medzery v otvorenom modali na safari po focuse inputu
.ogm-html__modal--close {
	background-color: #fff;
}

.ogm-html__modal--open {
	background-color: #000000b0;
}