input, textarea {
  box-sizing: border-box;
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
  width: 100%;
  height: toRem(45);
  font-size: toRem(16);


  background: #EDEFF2;
  border-radius: toRem(8);
  border: none;

  text-align: start;

  text-indent: toRem(15);

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica;

  text-transform: capitalize;

  &:focus, 
  &:active {
    border: none;
    outline: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.40);

    font-family: inherit;
    font-size: toRem(16);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-transform: none;
  }

  ::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
  }
  ::placeholder { /* Recent browsers */
    text-transform: none;
  }

  // margin-bottom: toRem(8);

  // &:last-child {
  //   margin-bottom: toRem(50);
  // }
}

textarea {
  height: 100%;
  resize: none;

  padding: toRem(8) 0 0 toRem(15);
  text-indent: 0;
}

textarea::placeholder {
  font-size: toRem(16);
}

input:focus,
input:focus-visible {
  border: none;
}


.input__error {
  display: block;
  margin: toRem(2) 0;
  font-size: toRem(12);
  line-height: toRem(14);
  text-align: center;
  color: #d31717;

  // margin-bottom: toRem(24);
}
