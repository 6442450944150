// @font-face {
//   font-family: "SFProDisplay"; /*Can be any text*/
//   font-weight: 700;
//   src: local("SFProDisplayBold"),
//     url("../font/SF-Pro-Display-Bold.otf") format("opentype");
// }

// @font-face {
//   font-family: "SFProDisplay"; /*Can be any text*/
//   font-weight: 300;
//   src: local("SFProDisplayLight"),
//     url("../font/SF-Pro-Display-Light.otf") format("opentype");
// }

// @font-face {
//   font-family: "SFProDisplay"; /*Can be any text*/
//   font-weight: 400;
//   src: local("SFProDisplayRegular"),
//     url("../font/SF-Pro-Display-Regular.otf") format("opentype");
// }

// @font-face {
//   font-family: "SFProDisplay"; /*Can be any text*/
//   font-weight: 500;
//   src: local("SFProDisplaySemibold"),
//     url("../font/SF-Pro-Display-Semibold.otf") format("opentype");
// }

// *,
// *::before,
// *::after {
//   box-sizing: border-box;
// }

@import "~bootstrap/scss/bootstrap";

html {
	height: 100%;
	height: 100svh;
	box-sizing: border-box;
	// overflow: auto;
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif;
	// hide scrollbars
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll;

	overscroll-behavior: none; // FIX pozadia po prescrollovani

	height: 100vh;
	// overflow: auto;
	box-sizing: border-box;
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
	height: 100svh;
	height: calc(100vh - 270px);
	background-color: #fff;
}
//
//body:has(dialog[open]) {
//  overflow: hidden;
//}
//
//body.modal-open {
//  overflow: hidden;
//}

body::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

@function toRem($value) {
	$remValue: calc(($value / 16)) + rem;
	@return $remValue;
}

// zevraj fix pozadia za strankou na on scroll reload NEFUNGUJE dobre
// html:before {
//   content: "";
//   margin-top: toRem(-999);
//   z-index: -1;
//   position: fixed;
//   width: 100%;
//   height: toRem(999);
//   top: toRem(-999);
//   background-color: #fff;
// }

@import "../../node_modules/flag-icons/css/flag-icons.min.css";
@import "../ui/atom/main";
@import "../ui/molecule/main";
@import "../ui/organism/main";
