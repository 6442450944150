.mol-error-page {
  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  margin: toRem(64) 0;
  // 91px = footer height
  height: calc(
    var(--app-height) - toRem(64) - toRem(91)
  ); // namiesto 100vh pouzivat --app-height fix safari
}

.mol-error-page__content-offset {
  padding: 0 toRem(20);
}
