.atm-content-wrapper {
  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  margin: toRem(64) 0;
  // 91px = footer height
  height: calc(100vh - toRem(64) - toRem(91));
}

.atm-content-wrapper--with-header {
  margin: toRem(24) 0;
  height: calc(100vh - toRem(24) - toRem(91));
}
