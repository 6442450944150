.mol-footer {
  text-align: center;
}

.mol-footer__logo {
  margin-bottom: toRem(10);
}

.mol-footer__copyright {
  text-transform: uppercase;

  font-size: toRem(7);
  font-weight: 400;
}
