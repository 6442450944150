.mol-reset-password-form__field {
  margin-bottom: toRem(8);
  font-size: toRem(16);
}

.mol-reset-password-form__field--last {
  margin-bottom: toRem(50);
}

.mol-reset-password-form__error {
  font-size: toRem(15);
  text-align: center;
  color: #d31717;

  margin-bottom: toRem(24);
}

.mol-reset-password-form__success {
  margin-bottom: toRem(14);
  font-size: toRem(18);
  font-weight: 500;
  text-align: center;
}
