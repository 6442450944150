.bond-toast {
  font-size: toRem(14);
  font-weight: 600;
  text-align: center;
  background-color: #fff;
  color: black;
  position: fixed;
  bottom: toRem(40);
  left: 0;
  right: 0;
  width: toRem(270);
  margin: 0 auto;
  padding: toRem(11);
  -webkit-box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.14);
	-moz-box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.14);
	box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.14);
  border: none;
  border-radius: toRem(10);
  z-index: 1;

  animation-name: slideInDown;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0,1.5,.5,1);
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -120px, 0) scaleY(.8);
    visibility: visible;
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}