.atm-detail-item--offset {
	margin-bottom: toRem(10);
}

.atm-detail-item {
	display: flex;

	position: relative;

	min-height: toRem(41);
}

.atm-detail-item__icon-wrapper {
	margin-right: toRem(14);
	margin-top: toRem(7);
	width: toRem(28);
	height: toRem(28);
	flex: 0 0 toRem(28);

	object-fit: cover;
}

.atm-detail-item__icon--image {
	border-radius: toRem(3);
	width: 100%;
	height: 100%;
}

// center icon
.atm-detail-item__icon-wrapper--no-description {
	margin-top: 0;
}

.atm-detail-item__icon {
	width: 100%;
	height: 100%;

	background-color: gray;
}

.atm-detail-item__icon--overlay {
	position: absolute;
	left: 0;
	top: toRem(9);

	width: 100%;
	height: 100%;
}

.atm-detail-item__name {
	margin-bottom: toRem(5);

	font-size: toRem(15);
	font-weight: 700;
	line-height: toRem(17);
}

.atm-detail-item__name--simple {
	margin-bottom: toRem(0);
	margin-top: toRem(2);
}

.atm-detail-item__value {
	font-size: toRem(13);
	font-weight: 300;
	line-height: toRem(19);

	color: black;

	text-decoration: none;
	word-break: break-word;
}

.atm-detail-item__value-pre-wrap {
	white-space: pre-wrap;
}

.atm-detail-item--simple {
	display: flex;

	align-items: center;
}

.atm-detail {
	&-text {
		background-color: #fff;
		color: black;
		font-size: toRem(13);
		font-weight: 300;
		border-radius: toRem(10);
		padding: toRem(15) toRem(20);
		margin-bottom: toRem(10);
		border: none;
		width: 100%;
		text-align: left;

		&-header {
			font-size: toRem(22);
			font-weight: 700;
			padding: toRem(30) toRem(0) toRem(5);
			margin-bottom: toRem(10);

			&.first {
				padding: toRem(18) toRem(0) toRem(5);
			}
		}
	}

	&-image {
		aspect-ratio: 2 / 1;
		background-color: rgb(255 255 255 / 30%);
		border-radius: toRem(10);
		overflow: hidden;
		margin-bottom: toRem(10);

		a {
			aspect-ratio: 2 / 1;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&-video {
		aspect-ratio: 16 / 9;
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: toRem(14);
		overflow: hidden;

		transform: scale(.7);
		-webkit-transform: scale(.7);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		width: 142.857143%;
		margin-bottom: calc(-24% + 10px);
	}
}

.atm-detail-spacer.first+.atm-detail-text-header {
	padding: toRem(18) toRem(0) toRem(10);
}