.mol-reset-password {
  width: 100%;
}

.mol-reset-password__text {
  text-align: center;
  margin-bottom: toRem(75);
}

.mol-reset-password__email {
  font-size: toRem(20);
  font-weight: 300;
}
