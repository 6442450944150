.atm-save-contact-button {
  // position: fixed;
  // bottom: toRem(0);

  padding-top: toRem(45);
  padding-bottom: toRem(35);

  display: flex;
  align-items: center;
  justify-content: center;
}

.atm-save-contact-button__action {
  border: none;
  border-radius: toRem(10);

  width: toRem(270);
  height: toRem(48);

  color: #fff; // TODO nastavovat tiez z API asi

  font-weight: 600;
  font-size: toRem(15);
  line-height: toRem(18);

  &:hover {
    cursor: pointer;
  }
}

.atm-save-contact-button__action-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
}