.atm-page-title {
  display: block;

  text-align: center;

  font-weight: 700;
  font-size: toRem(35);
  line-height: toRem(42);

  color: #000;
}
