.atm-cover-photo--standart {
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 45.33333%;
    width: 100%;
  }

  > .atm-cover-photo__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.atm-cover-photo--portrait {
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    width: 100%;
  }

  > .atm-cover-photo__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.atm-cover-photo__image {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}