.atm-user-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.atm-user-avatar__image-wrapper {
  position: relative;

  vertical-align: middle;
  width: toRem(160);
  height: toRem(160);

  // background-color: gray;
  border-radius: 50%;
  border: toRem(4) solid #fff;

  svg {
    width: 100%;
    height: 100%;
  }
}

.atm-user-avatar__image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;

  // background-color: gray;
}

.atm-user-avatar__company {
  position: absolute;
  right: toRem(0);
  bottom: toRem(0);
}

//.atm-user-avatar__username-wrapper {
//  display: flex;
//  align-items: center;
//
//  svg {
//    margin-left: toRem(8);
//    height: toRem(16);
//    width: toRem(16);
//    flex: 0 0 toRem(16);
//  }
//}
//
//.atm-user-avatar__username {
//  font-weight: 700;
//  font-size: toRem(25);
//  line-height: toRem(30);
//}

//.atm-user-avatar--hidden {
//  display: none;
//}
