.mol-connection-modal {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    width: 100dwh;
    height: 100vh;
    height: 100dvh;
    position: absolute;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.69);
    overflow: hidden;
}

.mol-connection-modal__centering {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 toRem(20);
}

html {
    overscroll-behavior: none;
}

// BOOTSTRAP ovverides
@media screen and (max-width: 575px) { 
    .modal-dialog-scrollable {
        height: calc(100% - toRem(15) * 2) !important;
    }

    .modal-dialog {
        margin: toRem(15) !important;
    }
}

.modal-content {
    padding: toRem(20) toRem(20) toRem(14) !important;
    border-radius: toRem(20) !important;
}

.modal-body {
    padding: unset !important;
}
