select {
  background-color: #f7f7f7;
  border: none;
  border-radius: 10px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 7px 28px 7px 10px;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* arrows */
.mol-select {
  background-image: linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%),
    linear-gradient(to right, #f7f7f7, #f7f7f7);
  background-position: calc(100% - toRem(15)) calc(1em + toRem(2)),
    calc(100% - toRem(10)) calc(1em + toRem(2)), 100% 0;
  background-size: toRem(5) toRem(5), toRem(5) toRem(5), 2.5em 2.5em;
  background-repeat: no-repeat;
}

.atm-language-select__flag-icon {
  margin-right: toRem(5);
}
