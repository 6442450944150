.ogm-card-detail__header {
	position: relative;

	text-align: center;

	background: #fff;
}

.ogm-card-detail__cover-wrapper {
	position: absolute;
	left: 0;
	top: 0;

	width: 100%;
}

.ogm-card-detail__header__save-hidden {
	display: block;
	height: toRem(55);
}

.ogm-card-detail__items {
	padding: toRem(20) toRem(20) toRem(100);
}

.ogm-card-detail__app-link {
	position: absolute;
	left: toRem(20);
	top: toRem(20);

	width: toRem(28);
	height: toRem(28);
}

.ogm-card-detail__user-info {
	padding: 0 toRem(30);
}

.ogm-card-detail__username {
	justify-content: center;

	margin-top: toRem(20);
}

.ogm-card-detail__position {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.ogm-card-detail__avatar--offset {
	// vyska profile image / 2
	padding-top: calc(45.33333% - 80px);
}
