.mol-layout-portrait__user-info {
	padding: toRem(20) toRem(20) toRem(0);

	display: flex;
	align-items: start;
	justify-content: space-between;
}

.mol-layout-portrait__user-info--right {
	display: flex;
	align-items: start;
	justify-content: start;
	flex-direction: column;

	.mol-detail-header__bio {
		text-align: start;
	}
}

.mol-layout-portrait__user-name {
	text-align: start;
}

.mol-layout-portrait__position {
	display: flex;
	align-items: start;
	justify-content: start;
	flex-direction: column;
}

.mol-layout-portrait__company-logo {
	margin-left: toRem(14);
}

.mol-layout-portrait--save-hidden {
	display: block;
	height: toRem(40);
}
