.ogm-body {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,#ededff 100%,#ededf0,#fff);

  .ogm-home__lang-select {
    max-width: toRem(475);
    display: flex;
    justify-content: end;
    margin: 0 auto;
    padding-bottom: 40px;

    & > div {
      position: relative;
      top: 30px;
      right: 20px;
      z-index: 1;
    }
  }
}

.ogm-home {
  position: relative;
  max-width: toRem(475);
  min-height: calc(var(--app-height) - 78px);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}


.ogm-home__content {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-bottom: 78px;

  h1 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    font-size: 13px;
    font-weight: 300;
    max-width: 100%;
    width: 200px;
    margin-top: -37px;
    z-index: 2;
  }
}

.ogm-home__icon-wrapper {
  position: relative;
  width: 260px;
  height: 260px;
  margin-top: 37px;
  
  &.mobile {
    width: 155px;
    height: 155px;
    margin-top: 47px;
    margin-bottom: 16px;
    
    .ogm-home__icon-background {
      top: 0;
      left: 0;
    }
  }

}

.ogm-home__icon {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover;
}

.ogm-home__icon--offset {
  margin-top: 31px;
  margin-bottom: 8px;
}

.ogm-home__icon-background {
  opacity: 0.6;
  background: linear-gradient(90deg, rgba(68, 188, 255, 0.80) 0%, rgba(68, 176, 255, 0.80) 23.44%, rgba(255, 68, 236, 0.80) 48.96%, rgba(255, 68, 236, 0.80) 73.96%, rgba(255, 103, 94, 0.80) 100%);
  filter: blur(42.22443389892578px);
  height: 155px;
  width: 155px;
  top: 32px;
  left: 52px;
  position: absolute;
  z-index: 1;
}

.ogm-home__icon-store {
  width: toRem(202);
}
