.atm-loader {
  display: flex;

  align-items: center;
  justify-content: center;

  width: 100%;
  height: var(--app-height);
}

.atm-loader__wrapper {
  width: toRem(30);
  height: toRem(30);

  // -webkit-transform: translateZ(0);
  // -moz-transform: translateZ(0);
  // -ms-transform: translateZ(0);
  // -o-transform: translateZ(0);
  // transform: translateZ(0);

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  // -webkit-perspective: 1000;
  // -moz-perspective: 1000;
  // -ms-perspective: 1000;
  // perspective: 1000;

  animation: rotation 0.6s infinite linear;
  -webkit-animation: rotation 0.6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
